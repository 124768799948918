import {
  ICaseResponse,
  ICaseSearchResult,
  ICasesListResponse,
  IIndustry,
  IPagination,
  IPopularSearch,
  IPostInfo,
  IPostsListResponse,
  ISearchResponse,
} from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import React, {
  useMemo, useReducer, useRef, useState,
} from 'react';
import client from '../../apiClient';
import ArticleCard from '../../page-components/Blog/ArticleCard';
import Chip from '../../page-components/Blog/Chip';
import CaseCard from '../../page-components/Cases/CaseCard';
import contactModalTriggerButton from '../../store/contactForm';
import isSearchOpen from '../../store/search';
import { theme } from '../../store/theme';
import useDebouncedCallback from '../../useDebounceCallback';
import { useOutsideClick } from '../../useOutsideClick';
import AnimatedTitle, { ANIMATED_TEXT_REGEX, AnimationType } from '../AnimatedTitle';
import Button, { ButtonVariants } from '../Button';
import styles from './Search.module.scss';
import searchReducer, { initialSearchResults, SearchType } from './searchReducer';
import {
  getCases, getSubservices, getPosts, getServices,
} from './searchRequests';

const getSearchAutocomplete = async (request: string) => {
  try {
    return await client.get<ISearchResponse>(`search?${new URLSearchParams({ request })}`, { cache: 'no-cache' });
  } catch (e) {
    console.error(e.message);
  }
};

function parseAutocompleteResult(resultItem: {
  title: string, projectName?: string,
}, query: string) {
  const escapedQuery = query.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escape special characters in the query
  let animatedPartRegEx = new RegExp(escapedQuery, 'gi');
  const titleString = resultItem.title.toLowerCase().includes(query.toLowerCase())
    ? resultItem.title : resultItem.projectName || '';
  return titleString.replaceAll(animatedPartRegEx, '<mark>$&</mark>');
}

const SearchLink = ({ text, url }: { text: string, url: string }) => (
  <Link
    href={url}
    className={styles.searchLink}
  >
    <AnimatedTitle
      title={text}
      animationType={AnimationType.NONE}
    />
    <svg className={styles.searchLink__icon}>
      <use
        xlinkHref='/media/icon-nest-bold.svg#iconNestBold'
        href='/media/icon-nest-bold.svg#iconNestBold'
      />
    </svg>
  </Link>
);

const SEARCH_LIMIT = 3;

type SearchProps = {
  quickSearches: IPopularSearch[] | null,
};
const Search = ({ quickSearches }: SearchProps) => {
  const [query, setQuery] = useState<string>('');
  const [activeQuery, setActiveQuery] = useState<string>('');
  const setSearchOpen = useSetAtom(isSearchOpen);
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const [autocompleteResults, setAutocompleteResults] = useState<ISearchResponse['result']>([]);
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const [searchResults, dispatchSearchResult] = useReducer(searchReducer, initialSearchResults);
  const isResultsEmpty = useMemo(() => (
    (searchResults.cases && searchResults.cases.cases?.length === 0)
    && searchResults.industries?.length === 0
    && (searchResults.posts && searchResults.posts.posts?.length === 0)
    && searchResults.services?.length === 0
  ), [searchResults]);
  const [casesOffset, setCasesOffset] = useState<number | null>(0);
  const [postsOffset, setPostsOffset] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appTheme = useAtomValue(theme);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  const showAutocompleteOptions = async (value: string) => {
    try {
      const results = await getSearchAutocomplete(value);
      const formattedResults = results?.result.map(resultItem => {
        const titleString = resultItem.title;
        let formattedText;

        const animatedPart = titleString.match(ANIMATED_TEXT_REGEX);
        if (animatedPart) {
          const divider = animatedPart.index;
          const textFirstPart = titleString.slice(0, divider);
          const textLastPart = divider !== undefined ? titleString.slice(divider + animatedPart[0].length) : '';
          formattedText = `${textFirstPart}${animatedPart[0].slice(2, animatedPart[0].length - 2)}${textLastPart}`;
        } else {
          formattedText = titleString;
        }
        return { ...resultItem, title: formattedText };
      });
      formattedResults && setAutocompleteResults(formattedResults as ISearchResponse['result']);
    } catch (e) {
      console.error(e.message);
    }
  };

  const onInputChange = useDebouncedCallback((value: string) => {
    showAutocompleteOptions(value);
  }, 500);

  const searchTypes = [
    {
      type: SearchType.POSTS_LOADED, fetchFunction: getPosts, offset: postsOffset, limit: SEARCH_LIMIT,
    },
    { type: SearchType.INDUSTRIES, fetchFunction: getServices },
    { type: SearchType.SERVICES, fetchFunction: getSubservices },
    {
      type: SearchType.CASES_LOADED, fetchFunction: getCases, offset: casesOffset, limit: SEARCH_LIMIT,
    },
  ];

  const updateOffset = (type: SearchType, pagination: IPagination) => {
    const isEnd = +pagination.offset + +pagination.quantity >= +pagination.total;
    const types = [{
      type: SearchType.CASES_LOADED,
      callback: setCasesOffset,
    }, {
      type: SearchType.POSTS_LOADED,
      callback: setPostsOffset,
    }];
    types.forEach(item => {
      if (type === item.type) {
        if (isEnd) {
          item.callback(null);
        } else {
          item.callback(prev => (prev || 0) + SEARCH_LIMIT);
        }
      }
    });
  };

  const onSearch = async (searchQuery: string) => {
    setIsLoading(true);
    setActiveQuery(searchQuery);
    setShowAutocomplete(false);
    setAutocompleteResults([]);
    setPostsOffset(0);
    setCasesOffset(0);
    dispatchSearchResult({ type: SearchType.CLEAR });

    const promises = searchTypes.map(async ({
      type, fetchFunction, offset, limit,
    }) => {
      try {
        const data = await fetchFunction(
          searchQuery,
          ((offset === null ? 0 : typeof offset === 'number' ? 0 : undefined) as number),
          (limit as number),
        );
        type === SearchType.CASES_LOADED && updateOffset(SearchType.CASES_LOADED, (data as ICasesListResponse)?.pagination);
        type === SearchType.POSTS_LOADED && updateOffset(SearchType.POSTS_LOADED, (data as IPostsListResponse)?.pagination);
        dispatchSearchResult({ type, data });
      } catch (e) {
        console.error(e.message);
      }
    });

    await Promise.allSettled(promises);
    setIsLoading(false);
  };

  const loadMoreCases = async () => {
    try {
      const data = await getCases(activeQuery, casesOffset || 0, SEARCH_LIMIT);
      dispatchSearchResult({ type: SearchType.LOAD_MORE_CASES, data });
      updateOffset(SearchType.CASES_LOADED, (data as ICasesListResponse).pagination);
    } catch (e) {
      console.error(e.message);
    }
  };

  const loadMorePosts = async () => {
    try {
      const data = await getPosts(activeQuery, postsOffset || 0, SEARCH_LIMIT);
      dispatchSearchResult({ type: SearchType.LOAD_MORE_POSTS, data });
      updateOffset(SearchType.POSTS_LOADED, (data as IPostsListResponse).pagination);
    } catch (e) {
      console.error(e.message);
    }
  };

  useOutsideClick(autocompleteRef, () => setShowAutocomplete(false));

  return (
    <div
      role='search'
      className={styles.search}
    >
      <button
        type='button'
        className={styles.searchCloseButton}
        onClick={() => setSearchOpen(false)}
      >
        <svg>
          <use
            xlinkHref='/media/menuClose.svg#menuCloseSVG'
            href='/media/menuClose.svg#menuCloseSVG'
          />
        </svg>
      </button>
      <div className={styles.search__content}>
        <header className={styles.search__header}>
          <div
            className={styles.inputBlock}
            ref={autocompleteRef}
          >
            <form
              className={styles.searchForm}
              onSubmit={e => {
                e.preventDefault();
                query.length > 2 && onSearch(query);
              }}
            >
              <input
                ref={searchInputRef}
                type='search'
                className={classnames(styles.input, {
                  [styles.input_error]: isResultsEmpty && query === activeQuery,
                })}
                placeholder='Search'
                value={query}
                onFocus={() => setShowAutocomplete(true)}
                onChange={e => {
                  setAutocompleteResults([]);
                  setQuery(e.target.value);
                  onInputChange(e.target.value);
                }}
              />
              {isLoading && (
                <img
                  src={`/media/spinner_${appTheme}.webp`}
                  alt='spinner'
                  className={styles.searchLoadingIcon}
                />
              )}
            </form>
            {showAutocomplete && autocompleteResults.length > 0 && (
              <div className={styles.autocomplete}>
                {autocompleteResults.map(item => (
                  <button
                    key={`${item.type}/${item.id}`}
                    type='button'
                    className={styles.autocompleteItem}
                    onClick={() => {
                      setQuery(item.title.toLowerCase().includes(query.toLowerCase())
                        ? item.title : (item as ICaseSearchResult).projectName || '');
                      onSearch(item.title.toLowerCase().includes(query.toLowerCase())
                        ? item.title : (item as ICaseSearchResult).projectName || '');
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: parseAutocompleteResult(item, query) }} />
                  </button>
                ))}
              </div>
            )}
          </div>
          {quickSearches && (
            <div className={styles.quickSearch}>
              <p className={styles.quickSearch__title}>Quick search</p>
              <div className={styles.quickSearch__content}>
                {quickSearches.map(item => (
                  <Chip
                    className={styles.quickSearch__chip}
                    key={item.id}
                    title={item.title}
                    onClick={() => {
                      setQuery(item.title);
                      showAutocompleteOptions(item.title);
                      setAutocompleteResults([]);
                      onSearch(item.title);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </header>
        <div className={styles.results}>
          {isResultsEmpty ? (
            <div className={styles.emptyResults}>
              <p className={styles.emptyResults__text}>
                Unfortunately, the result for “
                {activeQuery}
                ” wasn’t found. But we are here to help
                with your request!
              </p>
              <Button
                variant={ButtonVariants.OUTLINED}
                className={styles.emptyResults__button}
                icon={(
                  <svg>
                    <use
                      xlinkHref='/media/icon-nest.svg#iconNest'
                      href='/media/icon-nest.svg#iconNest'
                    />
                  </svg>
                )}
                iconPosition='right'
                iconSize={{ width: 24, height: 24 }}
                onClick={(e) => {
                  setSearchOpen(false);
                  setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Empty search button`);
                }}
              >
                Contact Us
              </Button>
            </div>
          ) : (
            <>
              {searchResults.industries.length > 0 && (
                <section className={styles.resultSection}>
                  <header className={styles.resultSection__header}>
                    <p className={styles.resultSection__category}>Category</p>
                    <p className={styles.resultSection__title}>Services</p>
                  </header>
                  <div className={styles.resultSection__links}>
                    {searchResults.industries.map((item: IIndustry) => (
                      <SearchLink
                        url={`/${item.slug}`}
                        text={item.title}
                        key={item.id}
                      />
                    ))}
                  </div>
                </section>
              )}
              {/* {searchResults.services.length > 0 && ( */}
              {/*  <section className={styles.resultSection}> */}
              {/*    <header className={styles.resultSection__header}> */}
              {/*      <p className={styles.resultSection__category}>Category</p> */}
              {/*      <p className={styles.resultSection__title}>Subservices</p> */}
              {/*    </header> */}
              {/*    <div className={styles.resultSection__links}> */}
              {/*      {searchResults.services.map((item: IService) => ( */}
              {/*        <SearchLink */}
              {/*          url={`/service/${item.slug}`} */}
              {/*          text={item.title} */}
              {/*          key={item.id} */}
              {/*        /> */}
              {/*      ))} */}
              {/*    </div> */}
              {/*  </section> */}
              {/* )} */}
              {searchResults.cases?.cases && searchResults.cases?.cases.length > 0 && (
                <section className={styles.resultSection}>
                  <header className={styles.resultSection__header}>
                    <p className={styles.resultSection__category}>Category</p>
                    <p className={styles.resultSection__title}>Cases</p>
                  </header>
                  <div className={styles.resultSection__content}>
                    <div className={styles.resultSection__articles}>
                      {searchResults.cases?.cases?.map((caseItem: ICaseResponse) => (
                        <Link
                          key={caseItem.id}
                          href={`/cases/${caseItem.slug}`}
                        >
                          <CaseCard
                            searchView
                            data={caseItem}
                          />
                        </Link>
                      ))}
                    </div>
                    {casesOffset !== null && (
                      <Button
                        variant={ButtonVariants.TRANSPARENT}
                        className={styles.loadMoreButton}
                        icon={(
                          <svg>
                            <use
                              xlinkHref='/media/load-more.svg#loadMore'
                              href='/media/load-more.svg#loadMore'
                            />
                          </svg>
                        )}
                        iconPosition='right'
                        iconSize={{ width: 24, height: 24 }}
                        onClick={loadMoreCases}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                </section>
              )}
              {searchResults.posts?.posts && searchResults.posts?.posts.length > 0 && (
                <section className={styles.resultSection}>
                  <header className={styles.resultSection__header}>
                    <p className={styles.resultSection__category}>Category</p>
                    <p className={styles.resultSection__title}>Blog</p>
                  </header>
                  <div className={styles.resultSection__content}>
                    <div className={styles.resultSection__articles}>
                      {searchResults.posts?.posts?.map((article: IPostInfo) => (
                        <ArticleCard
                          withoutDescription
                          searchView
                          data={article}
                          key={article.id}
                        />
                      ))}
                    </div>
                    {postsOffset !== null && (
                      <Button
                        variant={ButtonVariants.TRANSPARENT}
                        className={styles.loadMoreButton}
                        icon={(
                          <svg>
                            <use
                              xlinkHref='/media/load-more.svg#loadMore'
                              href='/media/load-more.svg#loadMore'
                            />
                          </svg>
                        )}
                        iconPosition='right'
                        iconSize={{ width: 24, height: 24 }}
                        onClick={loadMorePosts}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
