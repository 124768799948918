'use client';

import { IFooter } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import client from '../apiClient';
import clientDeviceSize, { ScreenSize } from '../store/deviceScreenSize';
import AddressBlock from './AddressBlock';

import styles from './Footer.module.scss';

import Logo from './Logo';

import SocialLink from './SocialLink';

const getFooterData = async () => {
  try {
    return await client.get<IFooter>('footer');
  } catch (e) {
    console.error(e.message);
    throw new Error(e);
  }
};

const Footer = () => {
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const logoWidth = deviceSize === ScreenSize.DESKTOP_HUGE ? 167 : 112;
  const logoHeight = deviceSize === ScreenSize.DESKTOP_HUGE ? 61 : 40;
  const [footerData, setFooterData] = useState<IFooter | null>(null);

  useEffect(() => {
    const getComponentData = async () => {
      try {
        const pageData = await getFooterData();

        setFooterData(pageData);
      } catch (e) {
        console.error(e.message);
      }
    };
    getComponentData();
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__main}>
        <div className={styles.footer__info}>
          <Logo
            width={logoWidth}
            height={logoHeight}
            className={styles.footer__logo}
          />
          <div className={styles.addresses}>
            {footerData && footerData.addresses.map(address => (
              <AddressBlock
                address={address}
                key={address.id}
              />
            ))}
            <div className={styles.socialsBlock}>
              <a
                target='_blank'
                href={footerData?.socials?.telegram}
                rel='noreferrer'
                className={styles.footer__telegram}
              >
                <svg>
                  <use
                    xlinkHref='/media/socials/telegram.svg#telegramSVG'
                    href='/media/socials/telegram.svg#telegramSVG'
                  />
                </svg>
                <span>{footerData?.socials?.telegram.replace('http://', '').replace('https://', '')}</span>
              </a>
              <div className={styles.footer__socials}>
                {
                  footerData?.socials?.linkedin && (
                    <SocialLink
                      icon='/media/socials/linkedin.svg'
                      id='linkedinSVG'
                      link={footerData?.socials?.linkedin}
                    />
                  )
                }
                {
                  footerData?.socials?.instagram && (
                    <SocialLink
                      icon='/media/socials/instagram.svg'
                      id='instagramSVG'
                      link={footerData?.socials?.instagram}
                    />
                  )
                }
                {
                  footerData?.socials?.facebook && (
                    <SocialLink
                      icon='/media/socials/facebook.svg'
                      id='facebookSVG'
                      link={footerData?.socials?.facebook}
                    />
                  )
                }
                {
                  footerData?.socials?.youtube && (
                    <SocialLink
                      icon='/media/socials/youtube.svg'
                      id='youtubeSVG'
                      link={footerData?.socials?.youtube}
                    />
                  )
                }
                {
                  footerData?.socials?.behance && (
                    <SocialLink
                      icon='/media/socials/behance.svg'
                      id='behanceSVG'
                      link={footerData?.socials?.behance}
                    />
                  )
                }
                {
                  footerData?.socials?.dribbble && (
                    <SocialLink
                      icon='/media/socials/dribble.svg'
                      id='dribbbleSVG'
                      link={footerData?.socials?.dribbble}
                    />
                  )
                }
              </div>
            </div>
          </div>

        </div>
        <div className={styles.footer__menu}>
          <div
            className={styles.block}
          >
            <p className={styles.block__title}>Services</p>
            <ul className={styles.block__content}>
              {footerData?.services?.map(service => (
                <li
                  className={styles.block__item}
                  key={service.id}
                >
                  <Link
                    href={`/${service.slug}`}
                    className={styles.block__link}
                  >
                    {service.formattedTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {footerData?.topics?.map(topic => (
            <div
              className={styles.block}
              key={topic.id}
            >
              <p className={styles.block__title}>{topic.title}</p>
              <ul className={styles.block__content}>
                {topic.items.map(topicItem => (
                  <li
                    className={styles.block__item}
                    key={topicItem.id}
                  >
                    <Link
                      href={`/${topicItem.link}`}
                      className={styles.block__link}
                    >
                      {topicItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.copyright}>
        <p className={styles.copyright__text}>
          {`© ${new Date().getFullYear()} SapientPro. All rights reserved`}
        </p>
        <div className={styles.copyright__rightBlock}>
          <Link
            href='/cookies-policy'
            className={styles.copyright__link}
          >
            Cookies Policy
          </Link>
          {/* <a */}
          {/*  href='src/components/Page/Footer#!' */}
          {/*  className={styles.copyright__link} */}
          {/* > */}
          {/*  Sitemap */}
          {/* </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
